import React, { useEffect, useState } from "react";
import {
  ArrowBackIcon,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "../../components/shared/MaterialUI.js";
import { useHistory } from "react-router-dom";
import { Form, Formik, ErrorMessage } from "formik";
import { useUI } from "../../app/context/ui.js";
import AppHelper from "../../helpers/AppHelper.js";
import { SettingsStyles } from "../../assets/css/settings-style.js";
import SettingsServiceNewApi from "../../services/newApi/SettingsServiceNewApi.js";

const Settings = () => {
  const history = useHistory();
  const { dialogUI, blockUI, snackbarUI } = useUI();
  const [states, setStates] = useState([]);
  const styles = SettingsStyles();
  const [stateDetails, setStateDetails] = useState([]);
  const [fieldsShippingMethods, setFieldsShippingMethods] = useState([])
  const settingsServiceNewApi = new SettingsServiceNewApi()

  const getStates = async () => {
    try {
      blockUI.current.open(true);
      const resp = await settingsServiceNewApi.getStates();
      setStates(resp?.data?.data);
      blockUI.current.open(false);
      return resp?.data;
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldsMethods = {
    "api": [{
      title: "Enter the API Endpoint",
      label: "Enter the API Endpoint: ",
      name: "apiEndpoint",
      placeholder: 'Type your API Endpoint',
      key: 1
    }],
    "email": [{
      title: "Enter email address here",
      label: "Enter email address here: ",
      name: "emails",
      placeholder: 'Type your email address here',
      key: 2
    }],
    "web": [{
      title: "Enter web address here",
      label: "Enter web address here: ",
      name: "address",
      placeholder: 'Type your web address here',
      key: 3
    },
    {
      title: "Enter username here",
      label: "Enter username here: ",
      name: "user",
      placeholder: 'Type your username here',
      key: 4

    },
    {
      title: "Enter password here",
      label: "Enter password here: ",
      name: "password",
      placeholder: 'Type your password here',
      key: 5
    }],
    "ftp": [{
      title: "Enter the FTP Key",
      label: "Enter the FTP Key: ",
      name: "FTPKey",
      placeholder: 'Type your FTP Key',
      key: 6
    }],
    "sftp": [{
      title: "Enter the SFTP Key",
      label: "Enter the SFTP Key: ",
      name: "SFTPKey",
      placeholder: 'Type your API Key',
      key: 7
    }]
  }

  const getDetailsState = async (id) => {
    try {
      blockUI.current.open(true);
      const response = await settingsServiceNewApi.getDetailStates(id);
      setStateDetails(response?.data?.data);
      blockUI.current.open(false);
      setFieldsShippingMethods(fieldsMethods[response?.data?.data?.shippingMethod?.method])
      return response?.data?.data;
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleStateSelect = (e) => {
    setFieldsShippingMethods([])
    setStateDetails([])
    const stateId = e;
    if (!stateId) return;
    getDetailsState(stateId);
  };

  const initialValues = {
    documentType: stateDetails?.documentType?.type,
    shippingMethod: stateDetails?.shippingMethod?.method,
    address: stateDetails?.shippingCredentialsActive ? stateDetails.shippingCredentialsActive?.address  : '',
    user: stateDetails?.shippingCredentialsActive ? stateDetails.shippingCredentialsActive?.user  : '',
    password: stateDetails?.shippingCredentialsActive ? stateDetails.shippingCredentialsActive?.password  : '',
    emails: stateDetails?.emails ? stateDetails.emails[0]?.email ?? '' : '',
  }

  const onSubmitSettings = (values) => {
    const DATA = {
      documentTypeId: stateDetails?.documentType?.id,
      shippingMethodId: stateDetails?.shippingMethod?.id,
      address: values?.address,
      user: values?.user,
      password: values?.password,
      emails: [values?.emails],
    }
    updateSettings(DATA)
  };

  const updateSettings = async (data) => {
    const settings = settingsModal()
    try {
      blockUI.current.open(true);
      await settingsServiceNewApi.updateSettingsForState(data, stateDetails?.stateId );
      dialogUI.current.open('Congratulations',"Your settings have been updated", settings);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const settingsModal = () => {
    const settings = {
      confirm: true,
      btn: { confirm: 'Close' },
      onConfirm: () => {dialogUI.current.close();},
      onBackdropClick: () => {},
      styles: { ...styles.dialog },
    };
    return settings
  }

  return (
    <Container
      component={"section"}
      disableGutters
      sx={{ padding: "0 48px" }}
      maxWidth="xl"
    >
      <Container
        disableGutters
        sx={{ padding: "3em 48px", backgroundColor: "#FFFFFF" }}
      >
        <Button onClick={() => history.push("/dashboard")}>
          <ArrowBackIcon />
        </Button>
        <Container disableGutters sx={{ padding: "20px 100px" }}>
          <Typography sx={{ fontSize: "1.5em", fontWeight: "600" }}>
            Settings
          </Typography>
          <Typography sx={{ fontSize: "1em" }}>
            In this interface you can select the format and delivery method
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmitSettings}
          >
            {(props) => {
              const { handleChange, handleBlur, values } = props;
              return (<Form>
                <Grid container direction="row" alignItems="center" spacing={3}>
                  <Grid item xs={12}>
                    <Grid item xs={6}>
                      <p>State</p>
                      <FormControl fullWidth>
                        <InputLabel>State</InputLabel>
                        <Select
                          label="State"
                          name="state"
                          onChange={(e) => {handleStateSelect(e.target.value)}}
                        >
                          {states?.map((key) => {
                            return (
                              <MenuItem key={key.id} value={key.id}>
                                {key.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {stateDetails.documentType && (
                    <Grid item xs={6}>
                      <p>Document type</p> 
                      <TextField
                        name="documentType"
                        label="Document Type"
                        placeholder="Document Type"
                        helperText={<ErrorMessage name="documentType" />}
                        variant="outlined"
                        value={stateDetails.documentType.type || ''}
                        onChange={(e) => 
                          {handleChange(e)
                        }}
                        fullWidth 
                        disabled
                      >
                      </TextField>
                    </Grid>
                  )}
                  
                  {stateDetails.shippingMethod && (
                    <Grid item xs={6}>
                      <p>Submission Method</p> 
                      <TextField
                        name="shippingMethod"
                        label="Submission Method"
                        placeholder="Submission Method"
                        helperText={<ErrorMessage name="shippingMethod" />}
                        variant="outlined"
                        value={stateDetails.shippingMethod.method || ''}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        fullWidth 
                        disabled
                      />
                    </Grid>
                  )}

                  {fieldsShippingMethods?.length > 0 && (
                    <>
                      {fieldsShippingMethods?.map((fieldMethod, i) => (
                        <Grid item xs={6} key={i}>
                          <p>{fieldMethod.title}</p>
                            <TextField
                              key={fieldMethod.key}
                              name={fieldMethod.name}
                              label={fieldMethod.label}
                              placeholder={fieldMethod.placeholder}
                              helperText={<ErrorMessage name={fieldMethod.name} />}
                              value={ values[fieldMethod.name] }
                              onChange={ handleChange }
                              variant="outlined"
                              fullWidth          
                            />
                        </Grid>
                        ))
                      }
                    </>
                  )}

                </Grid>
                <Box sx={styles.boxButtons}>
                  <Button
                    sx={styles.buttonCancel}
                    onClick={() => history.push("/dashboard")}
                  >
                    Cancel
                  </Button>
                  <Button 
                    sx={styles.button}
                    type='submit'>
                      Save Changes
                  </Button>
                </Box>
              </Form>)
            }
          }

          </Formik>
        </Container>
      </Container>
    </Container>
  );
};

export default Settings;
