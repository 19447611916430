import AppServiceNewApi from "./AppServiceNewApi";

class StatsServiceNewApi extends AppServiceNewApi{
    
    getStats(companyId){
        let filterString = '?'
        if(companyId)filterString = `${filterString}companyId=${companyId}`
        if(filterString === '?')filterString = ''
        else filterString = filterString.substring(0, filterString?.length)
        const url = `/api/internal/v1/stats${filterString}`
        return this.http.get(`${url}`)
    }

}
export default StatsServiceNewApi