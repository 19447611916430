/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useContext, useEffect, useRef } from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';

// @material-ui
import { Box, Paper, Typography } from '../../../components/shared/MaterialUI';

// @state
import { QuestionnaireContext } from '../Prescreening';
import { useUI } from '../../../app/context/ui';

// @assets
import { theme } from '../../../assets/theme';
import AppHelper from '../../../helpers/AppHelper';

// @services
import QuestStartServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';

const ThankYou = () => {
  const {
    initial,
    t,
    companyLogo,
    lang
  } = useContext(QuestionnaireContext);
  const { messages, confirmationCode } = initial;
  const questService = new QuestStartServiceNewApi()
  const { hashToken } = useParams();
  const { blockUI, snackbarUI } = useUI();
  const firstRender = useRef(true);
  const history = useHistory();

  const callWebhook = async () => {
    try {
      blockUI.current.open(true);
      await questService.callWebhook(hashToken)
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }
  
  useEffect(() => {
    if (firstRender.current) {
      callWebhook()
      firstRender.current = false;
    }
    if(initial?.isVeteran && !initial?.hasDD214Answer){
      setTimeout(() => {
        if(lang)return history.push(`/upload-file-dd214/${hashToken}?lang=${lang}`)
        return history.push(`/upload-file-dd214/${hashToken}`)
      }, 2000);
    }
  }, [])

  return (
    <>
       <Box
        sx={{
          position: 'fixed',
          left: '0',
          top: companyLogo ? '150px' : "60px",
          display: 'flex',
          height: '84vh',
          width: '100vw',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgb(249, 249, 249)',
          padding: '200px 24px',
        }}
      >
          <Paper
            elevation={0}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              padding: { xs: '0 16px', md: '0 48px' },
              width: { xs: '90%', md: '75%', lg: '50%' },
            }}
          >
            <Box 
              marginLeft={{ xs: '5%', md: '10%', lg: '15%' }}
              display="flex" 
              flexDirection="column" 
              gap={{ xs: '0.5rem', md: '2rem' }}
            >
              <Typography
                variant='h1'
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "700",
                  color: "#101010",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "2rem",
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "1.5rem",
                  },
                }}
              >
                {t("question.great")}
              </Typography>
              {!_isEmpty(messages) ? (
                <>
                  <Typography 
                    variant="body1"
                    sx={{
                      fontSize: "1.125rem",
                      color: "#101010",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "1rem",
                      },
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "0.75rem"
                    }}}
                    >{messages.statusMessage}.
                  </Typography>
                </>
              ) : (
                ''
              )}
              <>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.125rem",
                    color: "#101010",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.75rem",
                    },
                  }}
                >{t("question.codeConfirmation")} {confirmationCode}.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.125rem",
                    color: "#101010",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.75rem",
                    },
                  }}
                >{t("question.closeWindow")}
                </Typography>
              </>
            </Box>
          </Paper>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          left: '0',
          top: '92vh',
          height: '8vh',
          width: '100vw',
          background: 'black',
        }}
      />
    </>

  
  );
};

export default ThankYou;
