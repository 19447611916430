import AppServiceNewApi from "./AppServiceNewApi"

class HelpersServiceNewApi extends AppServiceNewApi{
    
    getLocalities(state, county){
        let params = '?'
        if(state)params = `${params}StateName=${state}&`
        if(county)params = `${params}CountyName=${county}&`
        if(params === '?')params = ''
        else params = params.substring(0, params?.length - 1)
        const url = `/api/internal/v1/helpers${params}`
        return this.http.get(`${url}`)
    }

    targetGropus(){
        return this.http.get(`/api/internal/v1/helpers/tiles`)
    }

}
export default HelpersServiceNewApi