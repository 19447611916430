import React from 'react';

import { DashboardStyles } from '../../../assets/css/dashboard-style';
import {
  AddIcon,
  Button,
  Link,
} from '../../../components/shared/MaterialUI';

export const AddNewEmployer = (props) => {
  const styles = DashboardStyles();

  return (
    <Link href='/create-employer' sx={{ textDecoration: 'none' }}>
      <Button
        variant='contained'
        color='primary'
        size='large'
        sx={styles.button}
        endIcon={<AddIcon />}
      >
        Add New Employer
      </Button>
    </Link>
  );
};
