import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Admin from './Admin/Admin';
import Employer from './Employer/Employer';

const Dashboard = () => {
  const user = useSelector((loadedState) => loadedState.user);
  const history = useHistory();
  const accessToken = user.accessToken;


  if (!accessToken) {
    history.push('/login');
  }

  return <>{user.role === 'super_admin' || user.role === 'admin' ? <Admin /> : <Employer />}</>;
};

export default Dashboard;
