// vendors
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @state
import { useUI } from "../app/context/ui";
import store from "../redux/store";

// @assets
import { HeaderStyles } from "../assets/css/general-style";
import logo from "../assets/images/logoDashboardPublic.svg";

// @helpers
import AppHelper from "../helpers/AppHelper";
import DialogUI from "./shared/DialogUI";

// @material-ui
import {
  AccountCircleRoundedIcon,
  AppBar,
  Box,
  CssBaseline,
  ExpandMoreIcon,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "./shared/MaterialUI";

const Header = ({companyLogo}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = useSelector((loadedState) => loadedState.user.companyId);
  const { snackbarUI } = useUI();
  const dialogUI = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const styles = HeaderStyles();
  const state = store.getState();

  const logout = async () => {
    try {
      dialogUI.current.close();
      dispatch({ type: "LOGOUT" });
      history.push("/login");
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const redirectToForm = () => {
    const pathname = window.location.pathname;
    const companyId = pathname.substring(pathname.lastIndexOf("/") + 1);
    const verifiedId = esUUIDValido(companyId) ? companyId : id
    history.push(`/forms/${verifiedId}`);
    
    /* if (!isNaN(parseInt(companyId))) {
      history.push(`/forms/${companyId}`);
    } else {
      history.push(`/forms/${id}`);
    } */
  }

  const esUUIDValido = (uuid) => {    
    const regexUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;    
    return regexUUID.test(uuid);
  }

  const renderMenu = (
    <Menu
      sx={{ mt: "50px" }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          const settings = {
            confirm: true,
            btn: {
              confirm: "Log out",
              close: "Cancel",
            },
            onConfirm: () => logout(),
            type: "logout",
            styles: { ...styles.dialog },
          };
          setAnchorEl(false);
          dialogUI.current.open(
            "Are you sure?",
            "You are about to log out.",
            settings
          );
        }}
      >
        <Typography textAlign="center" sx={{ fontSize: "16px" }}>
          Logout
        </Typography>
      </MenuItem>

      <MenuItem>Profile</MenuItem>

      <MenuItem
        onClick={() => redirectToForm()}
      >
        Forms
      </MenuItem>
      
      {state?.user?.isAdmin && state?.user?.hasNewToken ? (
        <MenuItem
          onClick={() => {
            history.push("/api-client");
          }}
        >
          Api Clients
        </MenuItem>
      ) : null}
      {state?.user?.isPayroll ? (
        <MenuItem
          onClick={() => {
            history.push("/payroll");
          }}
        >
          Payroll
        </MenuItem>
      ) : null}
    </Menu>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar disableGutters sx={styles.toolbar}>
          <Link href="/dashboard?page=1" sx={companyLogo ? styles.companyLogo : styles.logo}>
            <img src={companyLogo ? companyLogo : logo} alt="logo" />
          </Link>
          <Box className={styles.wrapperLogout}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              style={{ margin: "0" }}
            >
              <AccountCircleRoundedIcon sx={{ fontSize: { xs: 32, sm: 56 } }} />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ margin: "0" }}
            >
              <ExpandMoreIcon sx={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <DialogUI ref={dialogUI} />
    </>
  );
};

export default Header;