import AppServiceNewApi from "./AppServiceNewApi";

class AuthServiceNewApi extends AppServiceNewApi {
  loginNewApi(data) {
    const headers = { "Content-Type": "application/x-www-form-urlencoded", 'expires_in_hours':12 };
    return this.http.post("/auth/connect/token", data, { headers: headers });
  }

  recoverPassword(data) {
    const searchParams = new URLSearchParams();
    searchParams.append("email", data.email);

    return this.http.post(
      `/auth/v1/auth/recovery-password?${searchParams.toString()}`
    );
  }

  resetPassword(data) {
    const { token, password, password_confirmation } = data;
    const searchParams = new URLSearchParams();
    searchParams.append("token", token);
    searchParams.append("password", password);
    searchParams.append("confirmPassword", password_confirmation);

    return this.http.post(
      `/auth/v1/auth/reset-password?${searchParams.toString()}`
    );
  }

}

export default AuthServiceNewApi;
