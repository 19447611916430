// @vendors
import React, { useContext, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// @state
import { useUI } from "../../../../../app/context/ui";
import { EmployeeContext } from "../../../Employer";

// @assets
import { EmployeeTableStyles } from "../../../../../assets/css/employeeTable-style";

// @components
import DialogUI from "../../../../../components/shared/DialogUI";
import {
  ArrowForwardIcon,
  ArrowUpwardIcon,
  Box,
  Button,
  ClearAllIcon,
  FilterListRoundedIcon,
  Input,
  IconButton,
  InputAdornment,
  SearchRoundedIcon,
  TextField,
  Tooltip,
  Link,
  Grid,
  FileUploadRoundedIcon,
  FileDownloadOutlinedIcon,
} from "../../../../../components/shared/MaterialUI";
import { AddNewEmployee } from "./AddEmployee";
import FilterStatus from "./FilterStatus";
import CustomDialog from "../../CustomDialog/CustomDialog";

// @helperts
import AppHelper from "../../../../../helpers/AppHelper";
import { formDataUploadEmployees } from "../../../../../helpers/FunctionsUtils";

// @services
import SubmitterService from "../../../../../services/newApi/SubmitterServiceNewApi";
import CompanyService from "../../../../../services/newApi/CompanyServiceNewApi";
import EmployeeCompanyServiceNewApi from "../../../../../services/newApi/EmployeeCompanyServiceNewApi";

const TableToolbar = () => {
  const {
    search,
    setSearch,
    getAllEmployesByCompany,
    pages,
    companyId,
  } = useContext(EmployeeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const styles = EmployeeTableStyles();
  const id = open ? "simple-popover" : undefined;
  const [sendQuestionnaire, setSendQuestionnaire] = useState(false);
  useDispatch();

  const { blockUI, dialogUI, snackbarUI, progressUI } = useUI();
  const customDialogRef = useRef(null);
  const user = useSelector((loadedState) => loadedState.user);
  const companyService = new CompanyService();
  const submitterService = new SubmitterService();
  const employeeServiceNewApi = new EmployeeCompanyServiceNewApi();

  const settings = {
    confirm: true,
    btn: {
      confirm: "Close",
      close: "",
    },
    onConfirm: () => {
      dialogUI.current.close();
      getAllEmployesByCompany();
    },
    styles: { ...styles.dialog },
  };

  const submitSettings = {
    confirm: true,
    btn: {
      confirm: "Close",
      close: "",
    },
    onConfirm: () => dialogUI.current.close(),
    styles: { ...styles.dialog },
  };

  const uploadFile = async (e) => {
    if (e.target.files[0]) {
      const file = formDataUploadEmployees(e.target.files[0]);
      try {
        blockUI.current.open(true);
        await employeeServiceNewApi.chargeCsvFile(file, companyId);
        blockUI.current.open(false);
        dialogUI.current.open(
          "Great!",
          "Records were imported successfully!",
          settings
        );
      } catch (err) {
        blockUI.current.open(false);
        AppHelper.checkError(err, snackbarUI);
        e.target.value = "";
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const submitQuestionnaire = async () => {
    try {
      progressUI.current.open(
        "Your requests are being processed, please wait a moment",
        "",
        "success"
      );
      submitterService.setAccessToken(user.accessToken);
      const { data: all } = await submitterService.sendFormToState(
        companyId,
        user?.id
      );
      const { data } = all;

      progressUI.current.close();

      if (data.id) {
        return customDialogRef.current.open(
          "The forms have be successfully processed.",
          data
        );
      } else {
        dialogUI.current.open(
          "Thank you !",
          "There are no questionnaires ready for submission at this moment. Please try again later.",
          submitSettings
        );
      }
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
      progressUI.current.close();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resendQuestionnaires = async () => {
    try {
      blockUI.current.open(true);
      const res = await companyService.resendQuestionnaire(companyId);
      const message = res.data.message;
      dialogUI.current.open("Congratulations!", message, submitSettings);
      setSendQuestionnaire(true);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const downloadEmployees = async () => {
    try {
      blockUI.current.open(true);
      const res = await employeeServiceNewApi.exportEmployeesByCompany(
        companyId
      );
      fileToConvert(res);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const fileToConvert = (res) => {
    const blob = new Blob([res?.data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "employees.csv";
    link.click();
  };

  const onSelectChange = (status) => {
    setSearch((current) => ({
      ...current,
      status: status,
    }));
    const filters = { name: search.name, status: status };
    getAllEmployesByCompany(1, pages.size, filters);
    setAnchorEl(null);
  };

  const handleSearchBar = async (query) => {
    if (query) getAllEmployesByCompany(1, pages.size);
  };

  const onSearchBarChange = (e) => {
    setSearch((current) => ({
      ...current,
      name: e.target.value,
    }));
  };

  const clearSearch = () => {
    setSearch({
      name: "",
      status: "",
    });
    setAnchorEl(null);
    getAllEmployesByCompany(1, pages.size, {});
  };

  const actionToSelect = () => {
    if(search?.name === '')return clearSearch();
    if(search?.name?.length > 0)return handleSearchBar(search?.name);
    return
  }

  const getEmployeeTemplate = async () => {
    try {
      blockUI.current.open(true);
      const r1 = await employeeServiceNewApi.donwloadTemplete();
      blockUI.current.open(false);
      return r1;
    } catch (e) {
      debugger;
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const downloadTemplate = async () => {
    getEmployeeTemplate().then((response) => {
      let url = window.URL.createObjectURL(
        new Blob([response?.data], { type: "text/csv" })
      );
      let a = document.createElement("a");
      a.href = url;
      a.download = "template_employees.csv";
      a.click();
    });
  };

  return (
    <Box sx={styles.tableToolbar}>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={12} md={2}>
          <h2>Employee list</h2>
        </Grid>

        <Grid item xs={12} sm={12} md={5} sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
          <TextField
            sx={styles.searchInput}
            value={search?.name}
            id="outlined"
            placeholder="Search"
            onChange={(e) => onSearchBarChange(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if(search?.name === '') return clearSearch()
                return handleSearchBar(search?.name);
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={actionToSelect}>
                    <SearchRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Clear filter">
            <IconButton
              onClick={() => clearSearch()}
              sx={{width: '40px', height: '40px'}}
            >
              <ClearAllIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Tooltip title="Show filters">
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              sx={styles.filterButton}
            >
              Filter
              <FilterListRoundedIcon />
            </Button>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AddNewEmployee
            companyId={companyId}
            getEmployees={getAllEmployesByCompany}
          />
        </Grid>

        <Grid item xs={12} md={2.6}>
          <Tooltip title="Import">
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                type="file"
                sx={{ display: "none" }}
                onChange={(e) => uploadFile(e)}
              />
              <Button
                variant="contained"
                component="span"
                sx={styles.importButton}
              >
                Bulk Import
                <ArrowUpwardIcon />
              </Button>
            </label>
          </Tooltip>
        </Grid>

        <Grid item xs={12} md={3.4}>
          <Tooltip title="Re-send questionnaires">
            <Button
              variant="contained"
              sx={styles.submitListButton}
              onClick={resendQuestionnaires}
            >
              {sendQuestionnaire
                ? "Re-sent questionnaires"
                : "Re-send questionnaires"}
              <ArrowForwardIcon />
            </Button>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title="Export">
            <Link download onClick={downloadEmployees} sx={styles.exportLink}>
              <Button
                variant="contained"
                component="span"
                sx={styles.importButton}
              >
                Export
              </Button>
            </Link>
          </Tooltip>
        </Grid>

        <Grid item>
          <Link download onClick={downloadTemplate} sx={styles.downloadLink}>
            <Button
              sx={styles.csvButton}
              endIcon={<FileDownloadOutlinedIcon />}
            >
              Template
            </Button>
          </Link>
        </Grid>

        <Grid item>
          <Input
            accept=".csv"
            id="contained-button-file-upload-csv"
            type="file"
            sx={{ display: "none" }}
            onChange={(e) => uploadFile(e)}
          />
          <label htmlFor="contained-button-file-upload-csv">
            <Button variant="contained" component="span" sx={styles.csvButton}>
              Upload
              <FileUploadRoundedIcon />
            </Button>
          </label>
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          <Tooltip title="Submit">
            <Button
              variant="contained"
              sx={styles.submitButton}
              onClick={submitQuestionnaire}
            >
              Submit
              <ArrowForwardIcon />
            </Button>
          </Tooltip>
        </Grid>

        <FilterStatus
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          onSelectChange={onSelectChange}
          clearSearch={clearSearch}
        />
      </Grid>
      <DialogUI ref={dialogUI} companyId={companyId} />
      <CustomDialog onUpdate={getAllEmployesByCompany} ref={customDialogRef} />
    </Box>
  );
};

export default TableToolbar;
