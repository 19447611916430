export const types = {
  USER_ADD: 'USER_ADD',
  USER_UPDATE: 'USER_UPDATE',
  USER_REMOVE: 'USER_REMOVE',
  TAB_PAYROLL: 'TAB_PAYROLL',
  FILTER_PAYROLL: 'FILTER_PAYROLL',
  PAGINATION:'PAGINATION',
  EDIT_EMPLOYEE_PAYROLL: 'EDIT_EMPLOYEE_PAYROLL',
  CALL_PAYROLL_LIST: 'CALL_PAYROLL_LIST',
  ID_EMPLOYEE_SELECTED: 'ID_EMPLOYEE_SELECTED',
  SORT_EMPLOYEE: 'SORT_EMPLOYEE',
};
