import moment from 'moment/moment';
import { types } from '../redux/types';

export const dataToUpdateCompany = (data, status) => {
  const NEWDATA = {
    name: data?.name,
    tradeName: data?.tradeName,
    fein: data?.fein,
    address: data?.address,
    city: data?.city,
    county: data?.county,
    state: data?.state,
    stateId: data?.stateId,
    email: data?.email,
    password: '',
    zipCode: data?.zipCode,
    validatePassword: false,
    payrollContactName: data?.payrollContactName,
    payrollContactEmail: data?.payrollContactEmail,
    poaContact: data?.poaContact,
    poaEmail: data?.poaEmail,
    wotcContact: data?.wotcContact,
    wotcEmail: data?.wotcEmail,
    sponsor: data?.sponsor,
    sponsorEmail: data?.sponsorEmail,
    status: status,
    phoneNumber: data?.phoneNumber,
    employerRate: data?.employerRate ? data?.employerRate : 0,
    sendEmailToEmployeeWhenCreated: data?.sendEmailToEmployeeWhenCreated,
    isCountyOrCityManual: data?.isCountyOrCityManual,
    isWebhookConfigured: data?.isWebhookConfigured,
    webhookUrl: data?.webhookUrl,
    webhookSecret: data?.webhookSecret,
    externalCompanyId: data?.externalCompanyId,
    tiles: data?.tiles,
    signatureResponsibleName8850Form: data?.signatureResponsibleName8850Form,
    signatureResponsibleTitle8850Form: data?.signatureResponsibleTitle8850Form,
    signatureResponsibleName9061Form: data?.signatureResponsibleName9061Form,
    rbAcctId: data?.rbAcctId,
    displayName: data?.displayName,
    isAxiscare: data?.isAxiscare,
    site: data?.site,
    agency: data?.agency,
    hubspotId: data?.hubspotId,
  };
  return NEWDATA;
};

export const formDataUploadEmployees = (file) => {
  const formdata = new FormData();
  formdata.append('file', file);
  return formdata;
};

export const formattDate = (date) => {
  if (date) return moment(date).format('DD-MM-YYYY');
  return '';
};

export const validateEmptyString = (text) => {
  if (text === null) return '';
  else return text;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuItemProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const updateStateFilterPayroll = (dispatch, data) => {
  dispatch({
    type: types.FILTER_PAYROLL,
    EmployeeName: data?.EmployeeName ? data?.EmployeeName : null,
    Employeer: data?.Employeer ? data?.Employeer : null,
    targetGroup: data?.targetGroup ? data?.targetGroup : null,
    HireDateFrom: data?.HireDateFrom ? data?.HireDateFrom : null,
    HireDateTo: data?.HireDateTo ? data?.HireDateTo : null,
    StateStatus: data?.StateStatus ? data?.StateStatus : null,
    StateCertification: data?.StateCertification
      ? data?.StateCertification
      : null
  });
};

export const callListPayroll = (dispatch, action) => {
  dispatch({
    type: types.CALL_PAYROLL_LIST,
    payload: action
  });
};

export const stateCeretificationList = ['Yes', 'No', 'Pending'];

export const dataTestMaxedOut = {
  data: [
    {
      id: 1,
      lastSyncDate: '12-01-24',
      stateCert: 'Si',
      stateStatus: 'Approved',
      employeeFirstName: 'Isabel',
      employeeLastName: 'Naranjo',
      employeer: 'Saruman',
      employeerRate: '90',
      targetGroup: 'Veteran',
      targetGroupCode: 'Ba, Bc, Ad',
      hireDate: '12-01-24',
      paySchedule: 'Monthly',
      currentYTDGrossWages: '4,200.00',
      currentYTDHours: '420',
      wotcCreditMultiplier: '40',
      maxWage: '20,000.00',
      maxTaxCredit: '5,000.00',
      taxCreditYTD: '2,000.00',
      invoiceYTD: '1,000.00',
      actualInvoice: '1,000.00'
    },
    {
      id: 2,
      lastSyncDate: '12-01-24',
      stateCert: 'Si',
      stateStatus: 'Pending',
      employeeFirstName: 'Jose',
      employeeLastName: 'Avila',
      employeer: 'Gandalf',
      employeerRate: '90',
      targetGroup: 'Veteran',
      targetGroupCode: 'Ba, Bc',
      hireDate: '12-01-24',
      paySchedule: 'Monthly',
      ytdGross: '4,200.00',
      ytdHours: '420',
      wotcCredit: '40',
      maxWage: '20,000.00',
      maxTax: '5,000.00',
      taxCredit: '2,000.00',
      invoiceYtd: '1,000.00',
      actualInvoice: '1,000.00'
    },
    {
      id: 3,
      lastSyncDate: '12-01-24',
      stateCert: 'Si',
      stateStatus: 'Denied',
      employeeFirstName: 'Juliana',
      employeeLastName: 'Gonzalez',
      employeer: 'Legolas',
      employeerRate: '90',
      targetGroup: 'Veteran',
      targetGroupCode: 'Ba, Bc',
      hireDate: '12-01-24',
      paySchedule: 'Monthly',
      ytdGross: '4,200.00',
      ytdHours: '420',
      wotcCredit: '40',
      maxWage: '20,000.00',
      maxTax: '5,000.00',
      taxCredit: '2,000.00',
      invoiceYtd: '1,000.00',
      actualInvoice: '1,000.00'
    }
  ],
  pagination: {
    page_number: 1,
    page_size: 20,
    total_records: 3
  }
};

export const dataTestInvoice = {
  info: [
    {
      id: 1,
      lastSyncDate: '12-01-24',
      employeer: 'Arlei 1',
      invoiceYtd: '1,000.00',
      actualInvoice: '1,000.00',
      invoiceSent: true
    },
    {
      id: 2,
      lastSyncDate: '13-01-24',
      employeer: 'Arlei 2',
      invoiceYtd: '5,000.00',
      actualInvoice: '3,000.00',
      invoiceSent: false
    },
    {
      id: 3,
      lastSyncDate: '19-03-24',
      employeer: 'Arlei 3',
      invoiceYtd: '10,000.00',
      actualInvoice: '9,000.00',
      invoiceSent: true
    },
    {
      id: 4,
      lastSyncDate: '19-03-24',
      employeer: 'Arlei 4',
      invoiceYtd: '10,000.00',
      actualInvoice: '9,000.00',
      invoiceSent: true
    },
    {
      id: 5,
      lastSyncDate: '19-03-24',
      employeer: 'Arlei 5',
      invoiceYtd: '10,000.00',
      actualInvoice: '9,000.00',
      invoiceSent: false
    }
  ],
  pagination: {
    page_number: 1,
    page_size: 20,
    total_records: 5
  }
};

export const targetGroupCode = [
  { id: 1, name: 'Bc' },
  { id: 2, name: 'Ba' },
  { id: 3, name: 'Ab' },
  { id: 4, name: 'Cd' },
  { id: 5, name: 'Ad' },
  { id: 6, name: 'Bb' }
];

export const convertDateTolocaleTime = (date) => {
  // get date
  // const convertDate = new Date(date + ' UTC').toLocaleString()
  // let partsDate = convertDate.split(', ');
  // const getDate = partsDate[0];

  // // get day, month and year
  // let formattDate = getDate.split('/');
  // const day = parseInt(formattDate[0], 10);
  // const month = parseInt(formattDate[1], 10)-1;
  // const year = parseInt(formattDate[2], 10);
  // // create the date again and formater
  // let newDate = new Date(year, month, day)
  // return (moment(newDate).format('MM/DD/YYYY'))

  return Intl.DateTimeFormat(navigator.language || navigator.userLanguage, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).format(new Date(`${date} ${date.includes(':') ? '' : '12:00:00'} UTC`));
};

export const convertDateToNewFormat = (date) => {
  const splitDate = date.split('/');
  const day = splitDate[0];
  const month = splitDate[1];
  const year = splitDate[2];
  return `${month}/${day}/${year}`
}

export const ob365En = {
  welcome: 'Welcome',
  wait: 'WAIT!',
  changeToEarn: 'Don’t miss your chance to earn $250!',
  congratulations: 'Congratulations on joining Empire Workforce!',
  completeOnboardingProcess: 'To complete your onboarding process, we kindly ask that you fill out the Work Opportunity Tax Credit (WOTC) screening questionnaire. Rest assured, your employment with us is secure—this questionnaire is simply a routine part of our onboarding process and will not impact your application status in any way.',
  responsesConfidential: "Your responses are confidential and will only be used to determine our eligibility for valuable tax credits that help us continue to grow and support our team. Additionally, by completing the WOTC questionnaire fully and accurately, you'll be automatically entered into our monthly drawing to win a $250 gift card!.",
  question: 'Please provide the email you used in your onboarding process.',
  programVoluntary: 'This program is voluntary, but we appreciate your willingness to complete the following survey.'
}

export const ob365Es = {
  welcome: 'BIENVENIDO',
  wait: '¡ESPERA!',
  changeToEarn: '¡No pierdas la oportunidad de ganar $250!',
  congratulations: '¡Felicitaciones por unirte a Empire Workforce!',
  completeOnboardingProcess: 'Para completar tu proceso de incorporación, te pedimos que completes el cuestionario de selección de Crédito fiscal por oportunidad de trabajo (WOTC). Ten la seguridad de que tu empleo con nosotros está asegurado: este cuestionario es simplemente una parte rutinaria de nuestro proceso de incorporación y no afectará el estado de tu solicitud de ninguna manera.',
  responsesConfidential: "Tus respuestas son confidenciales y solo se utilizarán para determinar nuestra elegibilidad para créditos fiscales valiosos que nos ayudan a seguir creciendo y apoyando a nuestro equipo. Además, al completar el cuestionario WOTC de forma completa y precisa, ¡ingresarás automáticamente en nuestro sorteo mensual para ganar una tarjeta de regalo de $250!",
  question: 'Proporcione el correo electrónico que utilizó en su proceso de incorporación.',
  programVoluntary: 'Este programa es voluntario, pero apreciamos su disposición a completar la siguiente encuesta.'
}
