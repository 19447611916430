import { theme } from "../theme";

export const EmployerStyles = () => ({
  dashCtn: {
    color: '#101010',
    padding: '0 48px',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
  },
  upperCtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
  },
  adminView: {
    display: 'flex',
    '& p': {
      fontSize: '28px',
      fontWeight: 'bold',
    },
  },
  upperBoxes: {
    display: 'flex',
    margin: '0 0 64px 0',
  },
  profilePicCtn: {
    width: '100%',
    minWidth: '200px',
    height: '180px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
    },
  },
  profilePic: {
    borderRadius: '50%',
    width: '150px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'fill',
      objectPosition: '50% 50%',
    },
  },
  uploadProfilePic: {
    position: 'absolute',
    bottom: '4px',
    right: '8px',
    '& .MuiIconButton-root': {
      '&:hover': {
        background: '#101010',
        boxShadow: 4,
      },
    },
  },
  uploadIcon: {
    background: '#101010',
    color: '#FFF',
  },
  statsContainer: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '0 12px',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
  },
  statsBox: {
    width: '100%',
    height: '180px',
    padding: '0 64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: '#FFF',
    color: '#101010',
    '& .MuiCardContent-root': {
      textAlign: 'center',
      '& p:first-of-type': {
        fontSize: '1.25rem',
      },
      '& p:last-of-type': {
        fontSize: '2rem',
        fontWeight: '700',
      },
    },
  },
  statsIcon: {
    fontSize: '4rem',
  },
  buttonsBox:{
    display: 'flex',
    gap: 3,
  },
  settingsEmployer:{
    textDecoration: 'none',
    '& .MuiButton-root': {
      paddingX: '28px',
      width: '100%',
      height: '50px',
      fontSize: '1.25rem',
      color: '#101010',
      background: 'none',
      boxShadow: 'none',
      border: '1px solid #101010',
      textTransform: 'none',
      letterSpacing: '0',
      '&:hover': {
        background: 'inherit',
        boxShadow: 6,
      },
    },
  },
  editEmployer: {
    textDecoration: 'none',
    '& .MuiButton-root': {
      paddingX: '28px',
      height: '50px',
      fontSize: '1.25rem',
      color: '#101010',
      background: 'none',
      boxShadow: 0,
      border: '1px solid #101010',
      textTransform: 'none',
      letterSpacing: '0',
      '&:hover': {
        background: 'inherit',
        boxShadow: 6,
      },
    },
  },
  goBackToDash: {
    width: '32px',
    '& .MuiButton-root': {
      display: 'block',
      width: '20px',
      padding: '0',
      height: '32px',
      fontSize: '1.5rem',
      color: '#101010',
      background: 'none',
      boxShadow: 0,
      border: 'none',
      '&:hover': {
        background: 'inherit',
      },
    },
  },
  statusBox: {
    marginLeft: '50px',
    padding: '10px 0',
    width: '180px',
    height: '50px',
    background: '#101010',
    borderRadius: '4px',
    fontSize: '1.25rem',
    color: '#FFF',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  statusSelect: {
    width: '180px',
    height: '50px',
    fontSize: '1.25rem',
    textTransform: 'capitalize',
    border: 'none',
    textAlign: 'center',
    background: '#101010',
    marginLeft: '50px',
    color: '#FFF',
    '& .MuiSvgIcon-root': { color: '#FFF' },
  },
  buttonLinkGoBack:{
    margin: '-1.5em 0 3em 0',
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  containerDownload:{
    minHeight: "50vh",
    boxSizing: 'border-box !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    gap: '50px',
    alignItems: 'center',
    padding: '0px 50px 50px 50px',
    "::-webkit-scrollbar": {
      width: "10px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "rgba(16, 16, 16, 0.5)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  sectionToDownload:{
    width: '100%',
    height: '500px',
    border: '1px dashed #000',
    cursor: 'pointer',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
});
