// @vendors
import React, {
  createContext, 
  useEffect, 
  useReducer, 
  useState,
} from 'react';
import {
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  unset as _unset
} from 'lodash';
import { useHistory } from 'react-router-dom';
import SwipeableViews from '@gromy/react-swipeable-views';

// @material-ui
import {
  Container, 
  Paper, 
  Step, 
  StepLabel,
  Stepper,
} from '@mui/material';

// @state
import { useUI } from '../../../app/context/ui';
import { createEmployerReducer, initialState } from './state';

// @assets
import { CreateEmployerStyles } from '../../../assets/css/createEmployer-style';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @components
import FirstForm from './components/FirstForm';
import SecondForm from './components/SecondForm';

// @service
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi';
import { authForSuperAdmin } from '../../../helpers/GetTokenNewApi';
import store from '../../../redux/store';
import { useDispatch } from 'react-redux';

const steps = ['', ''];

const CreateEmployer = ({ id }) => {
  const [state, dispatch] = useReducer(createEmployerReducer, initialState);
  const { firstFormValues, secondFormValues } = state;
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const { blockUI, dialogUI, snackbarUI } = useUI();
  const styles = CreateEmployerStyles();
  const addCompanyService = new CompanyServiceNewApi()
  const [employerRate, setEmployerRate] = useState(0)
  const user = store.getState()
  const dispatchClient = useDispatch()

  useEffect(() => {
    if(dialogUI?.current === null)window.location.reload()
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStepDirection = (e, direction) => {
    if (direction === 'back') {
      setActiveStep((prev) => prev - 1);
      return;
    }
    if (activeStep === 0) setActiveStep((prev) => prev + 1);
  };

  const handleStepChange = (value, index) => {
    setActiveStep(index);
  };

  const onSubmit = async () => {
    let payload = { ...firstFormValues, ...secondFormValues };
    const INFO = dataToSend(payload)
    const settings = {
      confirm: true,
      btn: {
        confirm: 'Close',
        close: ''
      },
      onConfirm: () => {
        dialogUI.current.close();
        state.firstFormValues.name
          ? history.goBack()
          : history.push('/dashboard');
      },
      styles: { ...styles.dialog }
    };
    sendDataEmployeer(INFO, settings)
  };

  const getBooleanValue = (value) => {
    if(value === 'true' || value === true) return true
    return false
  }

  const dataToSend = (payload) => {
    const DATA = {
      name: payload?.name,
      tradeName: payload?.tradeName ? payload?.tradeName : null,
      fein: payload?.fein,
      address: payload?.address,
      city: payload?.city,
      county: payload?.county,
      state: payload?.state,
      stateId: payload?.stateId,
      email: payload?.email,
      password: payload?.password,
      validatePassword: payload?.password ? true : false,
      zipCode: payload?.zipCode,
      payrollContactName: payload?.payrollContactName ? payload?.payrollContactName : null,
      payrollContactEmail: payload?.payrollContactEmail ? payload?.payrollContactEmail : null,
      poaContact: payload?.poaContact ? payload?.poaContact : null,
      poaEmail: payload?.poaEmail ? payload?.poaEmail : null,
      wotcContact: payload?.wotcContact ? payload?.wotcContact : null,
      wotcEmail: payload?.wotcEmail ? payload?.wotcEmail : null,
      sponsor: payload?.sponsor ? payload?.sponsor : null,
      sponsorEmail: payload?.sponsorEmail ? payload?.sponsorEmail : null,
      status: 'active',
      phoneNumber: payload?.phoneNumber,
      employerRate: employerRate,
      sendEmailToEmployeeWhenCreated: getBooleanValue(payload?.sendEmailToEmployeeWhenCreated),
      isCountyOrCityManual: getBooleanValue(firstFormValues?.isCountyOrCityManual),
      isWebhookConfigured: getBooleanValue(payload?.isWebhookConfigured),
      webhookUrl: payload?.webhookUrl ? payload?.webhookUrl : null,
      webhookSecret: payload?.webhookSecret ? payload?.webhookSecret : null,
      externalCompanyId: payload?.externalCompanyId ? payload?.externalCompanyId : null,
      tiles: payload?.tiles,
      signatureResponsibleName8850Form: payload?.signatureResponsibleName8850Form ? payload?.signatureResponsibleName8850Form : null,
      signatureResponsibleTitle8850Form: payload?.signatureResponsibleTitle8850Form ? payload?.signatureResponsibleTitle8850Form : null,
      signatureResponsibleName9061Form: payload?.signatureResponsibleName9061Form ? payload?.signatureResponsibleName9061Form : null,
      rbAcctId: payload?.rbAcctId ? payload?.rbAcctId : null,
      displayName: payload?.displayName ? payload?.displayName : null,
      isAxiscare: getBooleanValue(payload?.isAxiscare),
      site: payload?.site ? payload?.site : null,
      agency: payload?.agency ? payload?.agency : null,
      hubspotId: payload?.hubspotId ? payload?.hubspotId : null,
    }
    return DATA
  }

  const callListClients = (r2) => {
    const role = user?.user?.role;
    if (role && role === "super_admin") {
      authForSuperAdmin(dispatchClient, snackbarUI, true);
    }
  };

  const sendDataEmployeer = (payload, settings) => {
    if (id) updateEmployer(payload, settings)
    else createEmployer(payload, settings)
  }

  const updateEmployer = async (payload, settings) => {
    try {
      blockUI.current.open(true);
      if (!_isUndefined(payload.password) && _isEmpty(payload.password)) {
        _unset(payload, 'password');
      }
      await addCompanyService.updateCompany(payload, id);
      openDialogUi(settings, 'updated')
      callListClients()
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const createEmployer = async (payload, settings) => {
    try {
      blockUI.current.open(true);
      await addCompanyService.addCompany(payload);
      openDialogUi(settings, 'created')
      callListClients()
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const openDialogUi = (settings, actionText) => {
    setTimeout(() => {
      blockUI.current.open(false);
      dialogUI.current.open('Great!', `Employer has been ${actionText} successfully.`, settings);    
    }, 100);
  }

  const updateValueRate = (value) => {
    setEmployerRate(value)
  }

  const contextProps = {
    state,
    dispatch,
    id,
    steps,
    activeStep,
    handleStepDirection,
    onSubmit,
    updateValueRate
  };

  return (
    <CompanyContext.Provider value={contextProps}>
      <Container
        component="section"
        maxWidth="xl"
        disableGutters
        sx={styles.dashCtn}
      >
        <Paper elevation={5} sx={styles.paper}>
          <Stepper connector={null} activeStep={activeStep} sx={styles.stepper}>
            <Step>
              <StepLabel />
            </Step>
            <Step>
              <StepLabel />
            </Step>
          </Stepper>
          <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
            <FirstForm />
            <SecondForm />
          </SwipeableViews>
        </Paper>
      </Container>
    </CompanyContext.Provider>
  );
};

export const CompanyContext = createContext({});
export default CreateEmployer;
