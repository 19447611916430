/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @state
import { useUI } from "../../app/context/ui";

// @assets
import { EmployerStyles } from "../../assets/css/employer-style";

// @components
import {
  Box,
  Button,
  Grid,
  Typography,
} from "../../components/shared/MaterialUI";
import AdminView from "./components/AdminView";
import EmployeeTable from "./components/EmployeeTable/EmployeeTable";
import EmployerStats from "./components/EmployerStats";

// @helperts
import AppHelper from "../../helpers/AppHelper";

// @services
import CompanyServiceNewApi from "../../services/newApi/CompanyServiceNewApi";
import EmployeeCompanyServiceNewApi from "../../services/newApi/EmployeeCompanyServiceNewApi";
import { types } from "../../redux/types";

const Employer = (props) => {
  const state = useSelector((loadedState) => loadedState);
  const user = state.user;
  const sort = state.sortEmployeeReducer
  const id = props.id ? props.id : user.companyId;
  const [employerDetails, setEmployerDetails] = useState({});
  const { blockUI, snackbarUI } = useUI();
  const styles = EmployerStyles();
  const comapnyServiceNewApi = new CompanyServiceNewApi()
  const employeeByCompanyService = new EmployeeCompanyServiceNewApi()
  const history = useHistory();
  const dispatch = useDispatch()
  const pageEmployer = 0;

  const [rowsState, setRowsState] = useState({
    pageSize: 20,
    rows: [],
    rowCount: 0,
    page: pageEmployer,
  });

  const [pages, setPages] = useState({
    page:0,
    size:10
  });

  const [search, setSearch] = useState({
    name: '',
    status: '',
  });

  const getAllEmployesByCompany = async (
    page=1,
    size=pages.size,
    filters=search,
    sorted=sort.sort,
    direction=sort.direction
  ) => {
    const paginattion = {page:page, size:size}
    try {
      blockUI.current.open(true);
      const response = await employeeByCompanyService.getEmployeesByCompany(id, paginattion, filters, sorted, direction );
      const data = response?.data
      updateListData(data)
    } catch (e) {
      blockUI.current?.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const updateListData = (data) => {
    setPages((prev) => ({
      ...prev,
      page: data?.pagination?.page_number,
      size: data?.pagination?.page_size
      ,
    }));
    setRowsState((prev) => ({
      ...prev,
      pageSize: rowsState.pageSize || 20,
      rows: data.data,
      rowCount: data?.pagination?.total_records,
      page: data?.pagination.page_number - 1,
    }));
    setTimeout(() => {
      blockUI.current.open(false);
    }, 1000);
  }

  useEffect(() => {
    dispatch({
      type: types.ID_EMPLOYEE_SELECTED,
      detail: ''
    })
    dispatch({
      type: types.SORT_EMPLOYEE,
      sort: '',
      direction: ''
    })
    getAllEmployesByCompany(1, 10, {}, '', '')
  }, [])

  const getEmployerDetails = async (id) => {
    try {
      const response = await comapnyServiceNewApi.getCompanyById(id);
      const data = response?.data?.data;
      setEmployerDetails({ name: data.name, status: data.status, data: data, displayName: data?.displayName });
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!id) {
      getEmployerDetails(user.companyId);
    } else {
      getEmployerDetails(id);
    }
  }, []);

  useEffect(() => {
    document.title = `RockerBox - ${employerDetails.name || ""}`;
  }, [employerDetails]);

  const adminProps = {
    id,
    name: employerDetails.name,
    displayName: employerDetails.displayName,
    status: employerDetails.status,
    setEmployerDetails,
    employerDetails
  };

  return (
    <>
      <Grid
        component="section"
        disableGutters
        sx={styles.dashCtn}
      >
        {pageEmployer !== 0 && (
          <Button
            onClick={() =>
              history.push({
                pathname: `/dashboard`,
                state: { page: pageEmployer },
              })
            }
            sx={styles.buttonLinkGoBack}
          >
            Return to dashboard
          </Button>
        )}

        <Box sx={styles.upperCtn}>
          {user.role === "super_admin" ? (
            <AdminView {...adminProps} />
          ) : (
            <>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    margin: "0 0 20px",
                  }}
                >
                  {employerDetails.name}
                </Typography>
                <Box sx={styles.statusBox}>{employerDetails.status}</Box>
              </Box>
              <Box sx={styles.buttonsBox}></Box>
            </>
          )}
        </Box>
        <EmployerStats {...adminProps} />
        <EmployeeContext.Provider
          value={{
            rowsState,
            pages,
            search,
            companyId: id || "",
            setRowsState,
            setPages,
            setSearch,
            getAllEmployesByCompany,
          }}
        >
          <EmployeeTable />
        </EmployeeContext.Provider>
      </Grid>
    </>
  );
};

export const EmployeeContext = createContext({});
export default Employer;
