import CreateEmployer from '../pages/Admin/CreateEmployer/CreateEmployer';
import Login from '../pages/auth/LoginPage';
import Dashboard from '../pages/Dashboard';
import Employer from '../pages/Employer/Employer';
import QuestionnaireResults from '../pages/Employer/components/EmployeeTable/components/QuestionnaireResults';
import Settings from '../pages/Admin/Settings';
import EmployeeForms from '../pages/Employer/components/EmployeeForms/EmployeeForms';
import ApiClients from '../pages/ApiClient/ApiClients';
import Payroll from '../pages/payroll/payroll';
import SearchEmployees from '../pages/searchEmployer/SearchEmployees';

const Routes = [
  {
    path: '/',
    name: 'login',
    exact: true,
    pageTitle: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: '',
    exact: true,
    pageTitle: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/create-employer',
    name: '',
    exact: true,
    pageTitle: 'Create Employer',
    component: CreateEmployer,
  },
  {
    path: '/edit-employer/:id',
    name: '',
    exact: true,
    pageTitle: 'Edit Employer',
    component: CreateEmployer,
  },
  {
    path: '/employer/:id',
    name: '',
    exact: true,
    pageTitle: 'Employer',
    component: Employer,
  },
  {
    path: '/results/:id',
    name: '',
    exact: true,
    pageTitle: 'Questionnaire Results',
    component: QuestionnaireResults,
  },
  {
    path: '/settings',
    name: '',
    exact: true,
    pageTitle: 'Admin Settings',
    component: Settings,
  },
  {
    path: '/forms/:id',
    name: '',
    exact: true,
    pageTitle: 'Forms',
    component: EmployeeForms,
  },
  {
    path: '/api-client',
    name: '',
    exact: true,
    pageTitle: 'Api Clients',
    component: ApiClients,
  },
  {
    path: '/payroll',
    name: '',
    exact: true,
    pageTitle: 'Payroll',
    component: Payroll,
  },
  {
    path: '/search-employees',
    name: '',
    exact: true,
    pageTitle: 'Search employees',
    component: SearchEmployees,
  },
];

export default Routes;
