/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ListItemText,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useFormik } from 'formik'
import { Box } from '@mui/system'
import moment from 'moment'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'

// @assets
import { DialogFilterPayroll } from '../../../assets/css/payroll-style'

// @material-ui
import {
  AdapterDateFns,
  LocalizationProvider,
  TextField,
  Button,
  Checkbox,
  Dialog,
  Grid,
  MenuItem,
  Select,
  Typography
} from "../../../components/shared/MaterialUI";

// @helpers
import { MenuItemProps, updateStateFilterPayroll } from '../../../helpers/FunctionsUtils'

// @services
import PayrollService from '../../../services/newApi/PayrollService'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { useUI } from '../../../app/context/ui'
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi'
import AppHelper from '../../../helpers/AppHelper'

const initialValuesForm = {
  nameEmployer: '',
  targetGroup: '',
  nameEmployee: '',
  startDate: null,
  endDate: null,
  stateStatus: null,
  stateCert: null
}

const PayrollFilter = ({handleClose, open, getRecordsList}) => {
  const style = DialogFilterPayroll()
  const { blockUI, snackbarUI } = useUI();
  const [initialValues, setInitialValues] = useState(initialValuesForm)
  const [groupsSelected, setGroupsSelected] = useState([])
  const [stateStatus, setStateStatus] = useState([])
  const dispatch = useDispatch()
  const [listCompanies, setListCompanies] = useState([])
  const companySNewApi = new CompanyServiceNewApi()
  const [valueCompany, setValueCompany] = useState()
  const [inputValue, setInputValue] = React.useState('');

  const [targetGroups, setTargetGroups] = useState([])
  const payrollService = new PayrollService()

  const validationForm = Yup.object({
    nameEmployer: Yup.string()
      .notRequired()
      .nullable(),
    targetGroup: Yup.string()
      .notRequired()
      .nullable(),
    startDate: Yup.date()
    .nullable()
    .notRequired()
    .typeError('Invalid date, "MM/dd/yyyy" format'),
    endDate: Yup.date()
    .nullable()
    .notRequired()
    .typeError('Invalid date, "MM/dd/yyyy" format'),
    nameEmployee: Yup.string().nullable().notRequired()
  });

  const formik = useFormik({
    initialValues:initialValues,
    onSubmit:(values) => {
      submit(values)
    },
    validationSchema: validationForm
  })

  //TODO: pending for clear the filter when the user select other tab
  const submit = () => {
    const DATA = {
      Employeer: valueCompany ? valueCompany : null,
      targetGroup: groupsSelected,
      HireDateFrom: initialValues.startDate ? moment(initialValues.startDate).format('MM/DD/YYYY') : null,
      HireDateTo: initialValues.endDate ? moment(initialValues.endDate).format('MM/DD/YYYY') : null,
      StateStatus: initialValues.stateStatus ? initialValues.stateStatus : null,
      EmployeeName: initialValues.nameEmployee ? initialValues.nameEmployee : null,
      StateCertification: initialValues.stateCert ? initialValues.stateCert : null
    }
    getRecordsList(1, 20, DATA)
    updateStateFilterPayroll(dispatch, DATA)
    handleClose()()
  }

  const handleChangeGroups = (event) => {
    const value = event.target.value
    setGroupsSelected(typeof value === 'string' ? value.split(',') : value,)
  }

  const handleDateChange = (value, field) => {
    if(field === 'startDate'){
      formik.values = {...formik.values, startDate: value}
      setInitialValues((prev) => ({ ...prev, startDate: value }));
    }else{
      formik.values = {...formik.values, endDate: value}
      setInitialValues((prev) => ({ ...prev, endDate: value }));
    }
  }
  
  const handleChangeStateCert = (e) => {
    setInitialValues((prev) => ({...prev, stateCert: e.target.value}))
  }
  
  const handleChangeStateStatus = (e) => {
    const check = e.target
    if(check.checked){
      setInitialValues((prev) => ({...prev, stateStatus: check.value}))
    }else{
      setInitialValues((prev) => ({...prev, stateStatus: null}))
    }
  }

  const handleChangeTypeText = (e) => {
    setInitialValues((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const getList = () => {
    if(initialValues.nameEmployer ) return true
    if(initialValues?.startDate) return true
    if(initialValues?.endDate) return true
    if(initialValues.nameEmployee) return true
    if(initialValues?.stateStatus) return true
    if(initialValues?.stateCert) return true
    if(initialValues?.targetGroup) return true
    if(groupsSelected.length) return true
    if(valueCompany) return true
    return false
  }

  const handleCloseFilters = () => {
    setValueCompany()
    updateStateFilterPayroll(dispatch)
    formik.resetForm()
    setGroupsSelected([])
    setInitialValues(initialValuesForm)
    handleClose()
    const canGetList = getList()
    if(canGetList)getRecordsList(1, 20, {})
  }

  const getListStatus = async () => {
    const response = await payrollService.getStateStatus()
    setStateStatus(response?.data?.data)
  }
  
  const getTargetGroups = async () => {
    const response = await payrollService.targetGroups()
    setTargetGroups(response?.data?.data)
  }

  const getAllCompanies = async (filters={}) => {
    try {
      blockUI.current.open(true);
      const response = await companySNewApi.getCompanies({page:1, size:20}, filters)
      setListCompanies(response?.data?.data)
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  useEffect(() => {
    getAllCompanies()
    getListStatus()
    getTargetGroups()
  }, [])

  const handleChangeEmployer = (e) => {
    if(e?.length > 2)getAllCompanies({name: e})
    if(!e)getAllCompanies()
  }
  
  return (
    <Dialog sx={style.dialog} open={open}>
      <Box sx={style.body}>
        <Typography sx={style.title}>Filters</Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box sx={style.form}>
              <Grid container spacing={0}>

                <Grid item xs={12} md={12} sx={style.marginDown}>
                  <FormControl fullWidth>
                      <Typography style={{marginBottom: 8}} sx={style.label}>
                        Employer Name
                      </Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        freeSolo
                        value={valueCompany}
                        disableClearable
                        onChange={(event, newValue) => { setValueCompany(newValue) }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                          handleChangeEmployer(newInputValue)
                        }}
                        name='nameEmployer'
                        options={listCompanies.map((option) => option.name)}
                        sx={{ width: 300, height: 22, marginBottom: '15px' }}
                        renderInput={(params) => <TextField
                            {...params}
                            label=""
                            size='small'
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        }
                      />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12} sx={style.marginDown}>
                  <FormControl fullWidth>
                    <Typography sx={style.label}>
                      Target Group
                    </Typography>
                    <Select
                      multiple
                      size="small"
                      name='targetGroup'
                      value={groupsSelected}
                      onChange={handleChangeGroups}
                      //input={<OutlinedInput sx={style.fieldsInput}/>}
                      sx={style.fieldsInput}
                      renderValue={(selected) => {
                        if (selected.length === 0) return <em>Select</em>;
                        return selected?.length > 2 
                          ? (<div>{selected[0]}, <spam style={style.otherValuesForGroups} >{`+ ${selected?.length -1} More`}</spam></div>)
                          : selected?.join(', ')
                      }}
                      MenuProps={MenuItemProps}
                      margin="dense" 
                    >
                      {targetGroups?.map((group, index) => (
                        <MenuItem
                          key={group?.id}
                          value={group?.targetGroup}
                          sx={style.menuItem}
                        >
                          <Checkbox
                            //checked={groupsSelected.indexOf(group?.id) > -1}
                            checked={groupsSelected.indexOf(group?.targetGroup) > -1}
                            sx={style.checkBox}
                            color="primary"
                          />
                          <ListItemText sx={style.textSelect} primary={group?.targetGroup} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12} sx={{marginBottom:'8px'}}>
                  <Typography sx={style.label}> Hire date</Typography>
                </Grid>

                <Grid item xs={12} md={5.5} lg={5.5}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      value={initialValues?.startDate}
                      onChange={(date) => handleDateChange(date, 'startDate')}
                      allowSameDateSelection={true}
                      sx={style.datePicker}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="startDate"
                          name="startDate"
                          size='small'
                          value={initialValues?.startDate}
                          error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                          helperText={formik.touched.startDate && formik.errors.startDate}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={1} lg={1}>
                  <Typography sx={style.to}>to</Typography>
                </Grid>

                <Grid item xs={12} md={5.5} lg={5.5}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      value={initialValues?.endDate}
                      onChange={(date) => handleDateChange(date, 'endDate')}
                      allowSameDateSelection={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          //sx={style.fieldsInput}
                          id="endDate"
                          name="endDate"
                          size='small'
                          value={initialValues?.endDate}
                          error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                          helperText={formik.touched.endDate && formik.errors.endDate}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
      
                <Grid item xs={12} md={12} lg={12} sx={{margin: '15px 0'}}>
                  <FormControl fullWidth>
                    <Typography sx={style.label}>
                      Employee Name
                    </Typography>
                    <TextField
                      id="nameEmployee" 
                      variant="outlined"
                      name='nameEmployee'
                      defaultValue=''
                      size="small"
                      sx={style.fieldsInput}
                      value={initialValues.nameEmployee}
                      onChange={(e)=> handleChangeTypeText(e)}
                      error={formik.touched.nameEmployee && Boolean(formik.errors.nameEmployee)}
                      helperText={formik.touched.nameEmployee && formik.errors.nameEmployee}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={style.label}> State Status</Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12} sx={{marginBottom: '10px'}}>
                  <FormGroup sx={style.formGroup} row>
                    {stateStatus?.map((state) => (
                      <FormControlLabel
                        control={<Checkbox
                            sx={style.checkBox}
                            size="small"
                            value={state?.stateStatus}
                            onChange={handleChangeStateStatus}
                            checked={initialValues.stateStatus === state?.stateStatus}
                          />
                        }
                        label={state?.stateStatus}
                        sx={style.labelCheckbox}
                        key={state?.id}
                      />
                    ))}
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" sx={style.label}>State Cert.</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      row
                      value={initialValues.stateCert}
                      onChange={handleChangeStateCert}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" sx={style.radio} />}
                        label="Yes"
                        sx={style.labelCheckbox}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" sx={style.radio}/>}
                        label="No"
                        sx={style.labelCheckbox}
                      />
                      <FormControlLabel
                        value="Pending"
                        control={<Radio size="small" sx={style.radio}/>}
                        label="Pending"
                        sx={style.labelCheckbox}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

              </Grid>
          </Box>
          <Box sx={style.divActions}>
            <Button
              onClick={handleCloseFilters}
              variant="contained"
              sx={style.buttonCancel}
              >
              Cancel
            </Button>
            <Button
              type='submit'
              variant="contained"
              sx={style.buttonApply}
              >
              Apply
            </Button>
          </Box>
        </form>
      </Box>
      
    </Dialog>
  )
}

export default PayrollFilter