// import { PlayCircleFilled } from '@mui/icons-material';
import React, { useEffect } from 'react';

import { HomeStyles } from '../../assets/css/home-style';
import { Box, Container, Grid, Link, PlayCircleFilled, Typography } from '../../components/shared/MaterialUI';
import logo from '../../assets/images/logoDashboardPublic.svg';

const Home = (props) => {
  const styles = HomeStyles();

  useEffect(() => {
    document.title = `RockerBox - ${props.title}`;
  });
  return (
    <>
      <Grid container columns={20} height='100vh'>
        <Grid 
          item 
          xs={11} 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center"
          gap={4}
        >
          <Box sx={styles.logo_container} >
            <img src={logo} alt='logo' />
          </Box>
     
          <Box display="flex" flexDirection="row" alignItems="baseline" gap={2} sx={styles.option_container}>
            <Typography variant="h1" sx={styles.option_title}>CLIENT</Typography>
            <Typography variant="h1" sx={styles.option_subtitle}>Payroll Login</Typography>
            <Link color="#607371">
            <PlayCircleFilled sx={styles.option_icon}/>
            </Link>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="baseline" gap={2} sx={styles.option_container}>
            <Typography variant="h1" sx={styles.option_title}>CPA</Typography>
            <Typography variant="h1" sx={styles.option_subtitle}>Payroll Login</Typography>
            <Link color="#607371">
             <PlayCircleFilled sx={styles.option_icon}/>
            </Link>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="baseline" gap={2} sx={styles.option_container}>
            <Typography variant="h1" sx={styles.option_title}>EMPLOYEE</Typography>
            <Typography variant="h1" sx={styles.option_subtitle}>Payroll Login</Typography>
            <Link color="#607371">
            <PlayCircleFilled sx={styles.option_icon}/>
            </Link>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="baseline" gap={2} sx={styles.option_container}>
            <Typography variant="h1" sx={styles.option_subtitle}>WOTC Login</Typography>
            <Link color="#607371" href="/login">
            <PlayCircleFilled sx={styles.option_icon}/>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Container component='div' sx={styles.home_cover} />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
