// @vendors
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// @state
import { useUI } from '../../../app/context/ui';

// @assets
import { EmployerStyles } from '../../../assets/css/employer-style';
import logo from "../../../assets/images/logoDashboardPublic.svg";

// @material-ui
import {
  AccountCircleRoundedIcon,
  Box,
  Card,
  IconButton,
  Input,
  Paper,
  PhotoCamera,
} from '../../../components/shared/MaterialUI';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @services
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi';

const EmployerProfilePic = ({ id }) => {
  const user = useSelector((loadedState) => loadedState.user);
  const companyId = id ? id : user.companyId;
  const [companyLogo, setCompanyLogo] = useState(null);
  const { blockUI, snackbarUI } = useUI();
  const styles = EmployerStyles();
  const companyServiceNewApi = new CompanyServiceNewApi()

  const getLogo = async (id) => {
    try {
      const r1 = await companyServiceNewApi.getLogo(id);
      if(r1?.data?.data?.logo.startsWith('http')) setCompanyLogo(r1?.data?.data?.logo);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const uploadFile = (e, id) => {
    const formdata = new FormData()
    formdata.append('logo', e.target.files[0])
    uploadImage(formdata, id)
  };

  const uploadImage = async (formdata, id) => {
    try {
      blockUI.current.open(true);
      await companyServiceNewApi.changeLogoToCompany(formdata, id);
      getLogo(companyId);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  useEffect(() => {
    getLogo(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card elevation={3} sx={styles.profilePicCtn}>
      <Paper elevation={4} sx={styles.profilePic}>
        {companyLogo ? (
          <img src={companyLogo ?? ''} alt='Company Logo' />
        ) : (
          <img src={logo ?? ''} alt='Rockerbox logo' />
        )}
        
        <Box sx={styles.uploadProfilePic}>
          <label htmlFor='icon-button-file'>
            <Input
              accept='image/*'
              id='icon-button-file'
              type='file'
              sx={{ display: 'none' }}
              onChange={(e) => uploadFile(e, companyId)}
            />
            <IconButton
              aria-label='upload picture'
              component='span'
              sx={styles.uploadIcon}
            >
              <PhotoCamera />
            </IconButton>
          </label>
        </Box>
      </Paper>
    </Card>
  );
};

export default EmployerProfilePic;
