// @vendors
import React, {useContext} from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

// @assets
import { toolbarStyles } from '../../../../assets/css/customToolbar-styles';
import { EmployeeTableStyles } from '../../../../assets/css/employeeTable-style';
import { formDataUploadEmployees } from '../../../../helpers/FunctionsUtils';

// @components material
import {
  Button,
  ClearAllIcon,
  FileUploadRoundedIcon,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '../../../../components/shared/MaterialUI';

// @components
import { DataTable } from '../../Admin';
import { AddNewEmployer } from '../AddNewEmployer';

// @context
import { useUI } from '../../../../app/context/ui';

// @helpers
import AppHelper from '../../../../helpers/AppHelper';

export const CustomToolbar = () => {
  const {
    getAllCompanies,
    search,
    setSearch,
    pages,
  } = useContext(DataTable);
  const styles = toolbarStyles();
  const stylesDialog = EmployeeTableStyles();
  const { blockUI, dialogUI, snackbarUI } = useUI();
  
  const onSearchBarChange = (e) => {
    setSearch((current) => ({
      ...current,
      name: e.target.value
    }));
  };

  const clearSearch = () => {
    setSearch((current) => ({
      ...current,
      name: ''
    }));
    getAllCompanies(1,pages.size, {});
  };

  const handleSearchBar = async (query) => {
    if(query){
      getAllCompanies(1,pages.size);
    }
  };

  const actionToSelect = () => {
    if(search?.name === '')return clearSearch();
    if(search?.name?.length > 0)return handleSearchBar(search?.name);
    return
  }

  const settings = {
    confirm: true,
    btn: {
      confirm: "Close",
      close: "",
    },
    onConfirm: () => {
      dialogUI.current.close();
      getAllCompanies(1, pages.size);
    },
    styles: { ...stylesDialog.dialog },
  };

  const uploadCsvFile = async (e) => {
    if (e.target.files[0]) {
      const file = formDataUploadEmployees(e.target.files[0]);
      console.log(file)
      try {
        blockUI.current.open(true);
        //await employeeServiceNewApi.chargeCsvFile(file, companyId);
        blockUI.current.open(false);
        dialogUI.current.open(
          "Great!",
          "Records were uploaded successfully!",
          settings
        );
        e.target.value = "";
      } catch (err) {
        blockUI.current.open(false);
        AppHelper.checkError(err, snackbarUI);
        e.target.value = "";
      }
    }
  };

  return (
    <GridToolbarContainer sx={styles.toolbar}>
      <h2>Employer list</h2>
      <Grid sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
        <TextField
          sx={styles.searchBox}
          value={search?.name}
          id='searchCompany'
          placeholder='Search'
          onChange={(e) => onSearchBarChange(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if(search?.name === '') return clearSearch()
              return handleSearchBar(search?.name);
            }
          }}
          variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  onClick={actionToSelect}
                >
                  <SearchRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title="Clear filter">
          <IconButton
            onClick={() => clearSearch()}
            sx={{width: '40px', height: '40px'}}
          >
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <GridToolbarFilterButton sx={styles.filterButton} />

      <AddNewEmployer />

      {/* <Grid>
        <input
          type='file'
          accept=".csv"
          id="contained-button-file-upload-csv-employer"
          style={{display: 'none'}}
          onChange={(e) => uploadCsvFile(e)}
        />
        <label htmlFor="contained-button-file-upload-csv-employer">
          <Button variant="contained" component="span" sx={stylesDialog.csvButton}>
            Upload csv
            <FileUploadRoundedIcon />
          </Button>
        </label>
      </Grid> */}
      
    </GridToolbarContainer>
  );
};
