import { theme } from "../theme";

export const DashboardStyles = (props) => ({
  dashCtn: {
    color: '#101010',
    padding: '0 48px',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
  },
  employerLink: {
    paddingLeft: '25px',
    textDecoration: 'none',
    color: '#101010',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '12px',
    },
  },
   upperCtn: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '64px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '16px',
      padding: '0px 16px',
    },
  },
  buttonCtn: {
    [theme.breakpoints.down('md')]: {
      padding: '0px 16px',
    },
  },
  button: {
    width: '100%',
    maxHeight: '64px',
    height: '56px',
    fontSize: '1.2rem',
    textTransform: 'none',
    backgroundColor: '#101010',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#101010',
    },
    '& .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '40px',
      fontSize: '1rem',
      '& .MuiButton-endIcon>*:nth-of-type(1)': {
        fontSize: '1.2rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formControl: {
    minWidth: 200,
    fontSize: '10px',
  },
  statusSelect: {
    height: '50px',
    width: '100%',
    textAlign: 'center',
    color: '#101010',
    fontSize: '20px',
    border: 'none',
    backgroundColor: '#FFF',
    '& .MuiOutlinedInput-input': {
      textTransform: 'capitalize',
    },
    '& 	.MuiSelect-icon': {
      color: '#101010',
    },
    '& 	.MuiOutlinedInput-notchedOutline': {
      border: '1px solid #101010',
    },
    [theme.breakpoints.down('md')]: {
      height: '40px',
      fontSize: '14px',
    },
  },
  tableGrid: {
    minHeight: 965,
    borderRadius: '4px',
    fontSize: '1rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaders' : {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      fontSize: '22px',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
      '& .MuiDataGrid-columnHeader': {
        padding: '0',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '700',
        paddingLeft: '32px',
      },
    },
    '& .MuiDataGrid-row': {
      fontSize: '20px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '30px',
    },
    '& .MuiDataGrid-virtualScroller': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '0 !important',
      },
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(16, 16, 16, 0.5)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    '& 	.MuiDataGrid-checkboxInput': {
      '& .MuiSvgIcon-root': {
        fontSize: '24px',
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
        },
      },
    },
  },
  stateConfig:{
    textDecoration: 'none',
    '& .MuiButton-root': {
      paddingX: '28px',
      width: '100%',
      height: '50px',
      fontSize: '1.25rem',
      color: '#101010',
      background: 'none',
      boxShadow: 'none',
      border: '1px solid #101010',
      textTransform: 'none',
      letterSpacing: '0',
      '&:hover': {
        background: 'inherit',
        boxShadow: 6,
      },
    },
  },
  buttonTools: {
    borderRadius: '4px',
    boxShadow: 'none',
    color: '#101010',
    justifyContent: 'center',
    '& .MuiButtonGroup-grouped': {
      color: '#101010',
      height: '50px',
      padding: '0',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
      '&::after': {
        position: 'absolute',
        content: '""',
        height: '2px',
        borderRadius: '24px',
        bottom: '12px',
        left: 'calc(50% - 4px)',
        right: '0',
        width: '14px',
        background: '#101010',
      },
      [theme.breakpoints.down('md')]: {
        height: '40px',
        '& .MuiSvgIcon-root': {
          fontSize: '1.2rem',
        },
      },
    },
    wrapperTable: {
      minHeight: 965,
      width: '100%',
      backgroundColor: '#FFF',
    },
  },
});
