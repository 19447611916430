import { sub } from 'date-fns';
import * as Yup from 'yup';
//.matches(/^[a-zA-ZÀ-ÿ\s']+$/, "Not numbers allowed")

export const validationSchema = (isManual, isAdmin, minDateStartedJob) => {
  return Yup.object({
    firstName: Yup.string('Enter your first name')
    .max(50, 'Max. 50 characters')
    .required('First name is required')
    .matches(/^[a-zA-ZÀ-ÿ -\s']+$/, "Not numbers allowed")
    .typeError(`First name can't be empty`),
    lastName: Yup.string('Enter your last name')
    .max(50, 'Max. 50 characters')
    .required('Last name is required')
    .matches(/^[a-zA-ZÀ-ÿ -\s']+$/, "Not numbers allowed")
    .typeError(`Last name can't be empty`),
    middleName: Yup.string('Enter your middleName')
    .max(50, 'Max. 50 characters')
    .matches(/^[a-zA-ZÀ-ÿ -\s']+$/, "Not numbers allowed")
    .nullable(),
    phoneNumber: Yup.string()
      .required('Telephone is required')
      .typeError(`Telephone can't be empty`),
    address: Yup.string('Enter the employee address')
      .required('Address is required')
      .typeError(`Address can't be empty`),
    state: Yup.string('Enter the state')
      .required('State is required')
      .typeError(`State can't be empty`),
    county: isManual ? null : Yup.string('Enter the county')
      .required('County is required')
      .typeError(`County can't be empty`),
    city: isManual ? null : Yup.string('Enter the city')
      .required('City cis required')
      .typeError(`City can't be empty`),
    otherCounty: !isManual ? null : Yup.string('Enter the county')
      .required('County is required')
      .typeError(`County can't be empty`),
    otherCity: !isManual ? null : Yup.string('Enter the city')
      .required('City cis required')
      .typeError(`City can't be empty`),
    email: Yup.string('Enter the employee Email')
      .email('Invalid email')
      .max(50, 'Max. 50 characters')
      .required()
      .typeError(`Email can't be empty`),
    zipCode: Yup.string('Enter your ZIP Code')
      .matches(/^\d+$/, 'zip code must be only numbers')
      .max(5, 'zip code must be only 5 digits')
      .min(5, 'zip code must be 5 digits')
      .required('ZIP Code is required')
      .nullable(),
    socialNumber: Yup.string()
      .min(11, 'Please complete the SSN')
      .max(11, 'Please complete the SSN')
      .required('SSN is required')
      .typeError(`SSN can't be empty`),
    jobPosition: isAdmin ? null : Yup.string('Enter the job position')
      .required('Job position is required')
      .typeError(`Job position can't be empty`),
    hourlyStartWage: isAdmin ? null : Yup.string()
      .required('Hourly start wage is required')
      .typeError(`Hourly start wage can't be empty`)
      .test('is-more-than-zero', 'Has to be greater than 0', (value) => {
        return Number(value) > 0;
      }),
    dateBirth: Yup.date()
      .max(sub(new Date(), {days: 1}), 'Invalid date')
      .test('is-more-than-16', 'Employee must be at least 16 years old', (value) => {
        return sub(new Date(), {years: 16}) > value;
      })
      .required('Date of birth is required')
      .typeError('Invalid date, "MM/dd/yyyy" format'),
    occupationCode: isAdmin ? null : Yup.string('Enter the occupation')
      .required('Occupation is required')
      .typeError(`Occupation can't be empty`),
    dateGaveInfo: isAdmin
      ? Yup.date()
      .nullable()
      .notRequired()
      .typeError('Invalid date, "MM/dd/yyyy" format')
      : Yup.date()
      .nullable()
      .required('Date of info is required')
      .typeError('Invalid date, "MM/dd/yyyy" format')
      .min(new Date(1900, 11, 31, 23, 59, 29), 'Enter a valid date')
      .transform((v) => (v ? v : null)),
    externalEmployeeId: Yup.string().nullable().notRequired(),
    dateStartedJob: Yup.date()
      .nullable()
      .notRequired()
      .min(minDateStartedJob, 'The date cannot be less than the date of hire or the date the job was offered.')
      .typeError('Invalid date, must follow "MM/dd/yyyy" format'),
    // dateWasOfferedJob: Yup.date()
    //   .required('Date of offer is required')
    //   .typeError('Invalid date, must follow "MM/dd/yyyy" format'),
    // dateWasHired: Yup.date()
    //   .required('Date of hire is required'),
  });
}
