// @vendors
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";

// @state
import { useUI } from "../../app/context/ui";


// @assets
import { EmployeeTableStyles } from "../../assets/css/employeeTable-style";

// @components
import { SearchEmployeeContext } from "./SearchEmployees";
import {
  Box,
  Button,
  ClearAllIcon,
  FilterListRoundedIcon,
  IconButton,
  InputAdornment,
  SearchRoundedIcon,
  TextField,
  Tooltip,
  Link,
  Grid,
} from "../../components/shared/MaterialUI";
import FilterStatus from "../Employer/components/EmployeeTable/components/FilterStatus";

// @helperts
import AppHelper from "../../helpers/AppHelper";

// @services
import EmployeeCompanyServiceNewApi from "../../services/newApi/EmployeeCompanyServiceNewApi";

const TableToolbarSearch = (companyId) => {
  const {
    search,
    setSearch,
    getAllEmployesByCompany,
    pages,
  } = useContext(SearchEmployeeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const styles = EmployeeTableStyles();
  const id = open ? "simple-popover" : undefined;
  useDispatch();

  const { blockUI, snackbarUI } = useUI();
  const employeeServiceNewApi = new EmployeeCompanyServiceNewApi();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadEmployees = async () => {
    try {
      blockUI.current.open(true);
      const res = await employeeServiceNewApi.exportEmployeesByCompany();
      fileToConvert(res);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const fileToConvert = (res) => {
    const blob = new Blob([res?.data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "employees.csv";
    link.click();
  };

  const onSelectChange = (status) => {
    setSearch((current) => ({
      ...current,
      status: status,
    }));
    const filters = { name: search.name, status: status };
    getAllEmployesByCompany(1, pages.size, filters);
    setAnchorEl(null);
  };

  const handleSearchBar = async (query) => {
    if (query) getAllEmployesByCompany(1, pages.size);
  };

  const onSearchBarChange = (e) => {
    setSearch((current) => ({
      ...current,
      name: e.target.value,
    }));
  };

  const clearSearch = () => {
    setSearch({
      name: "",
      status: "",
    });
    setAnchorEl(null);
    getAllEmployesByCompany(1, pages.size, {});
  };

  const actionToSelect = () => {
    if(search?.name === '')return clearSearch();
    if(search?.name?.length > 0)return handleSearchBar(search?.name);
    return
  }

  return (
    <Box sx={styles.tableToolbar}>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={12} md={2}>
          <h2>Employee list</h2>
        </Grid>

        <Grid item xs={12} sm={12} md={5} sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
          <TextField
            sx={styles.searchInput}
            value={search?.name}
            id="searchByEmployee"
            placeholder="Search"
            onChange={(e) => onSearchBarChange(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if(search?.name === '') return clearSearch()
                return handleSearchBar(search?.name);
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={actionToSelect}>
                    <SearchRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Clear filter">
            <IconButton
              onClick={() => clearSearch()}
              sx={{width: '40px', height: '40px'}}
            >
              <ClearAllIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Tooltip title="Show filters">
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              sx={styles.filterButton}
            >
              Filter
              <FilterListRoundedIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Export">
            <Link download onClick={downloadEmployees} sx={styles.exportLink}>
              <Button
                variant="contained"
                component="span"
                sx={styles.importButton}
              >
                Export
              </Button>
            </Link>
          </Tooltip>
        </Grid>

        <FilterStatus
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          onSelectChange={onSelectChange}
          clearSearch={clearSearch}
        />
      </Grid>
    </Box>
  );
};

export default TableToolbarSearch;
