// @vendors
import React, { useId } from 'react'
import {
  Box,
  Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar
  } from "../../components/shared/MaterialUI";
import { StyleAPiClient } from './StyleApiClient';
import RowClient from './RowClient';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import CreateClientModal from './CreateClientModal';

const TableApiClient = ({data, getAllClients}) => {
  const styles = StyleAPiClient()
  const idTable = useId()
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false)

  const openModalCreate = () =>{
    setOpenModal(current => !current)
  }

  return (
    <Paper elevation={4}>
      <Toolbar sx={styles.toolbar}>
        <Box sx={styles.header}>
          <Button
            color="inherit"
            onClick={() => history.push('/Dashboard')}
            sx={styles.btnReturn}
          >
            Return To Dashboard
          </Button>
          <Button
            color="inherit"
            onClick={() => openModalCreate()}
            sx={styles.btnAdd}
          >
            Add api client
          </Button>
        </Box>
      </Toolbar>
      <TableContainer sx={styles.tableContainer} component={Paper}>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow sx={styles.tableRow} key={idTable}>

              <TableCell sx={styles.emptyTableCell} />
              <TableCell sx={styles.tableHeadTitle}>Client name</TableCell>        
            </TableRow>
          </TableHead>
          <TableBody sx={styles.tableBody}>
            {data?.map((client, index) => (
              <RowClient key={index} row={client}/>
            ))}            
          </TableBody>
        </Table>
      </TableContainer>
      <CreateClientModal open={openModal} onClose={openModalCreate} getAllClients={getAllClients} />
    </Paper>
  )
}

export default TableApiClient