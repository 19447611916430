// @vendors
import React, { useContext } from "react";

// @components
import {
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  useGridApiContext,
} from "../../../../components/shared/MaterialUI.js";
import { DataTable } from "../../Admin";

export const CustomFiltering = () => {
  const {
    getAllCompanies,
    status,
    pages,
    setSearch,
    search
  } = useContext(DataTable);
  const apiRef = useGridApiContext();

  const onSelectChange = (e) => {
    e.preventDefault();
    const selectedValue = e.target.value;
    setSearch((current) => ({
      ...current,
      status: selectedValue
    }));
    const filters = {name: search.name, status:selectedValue}
    getAllCompanies(1, pages.size, filters);
    apiRef.current.hideFilterPanel();
  };

  const clearFilters = () => {
    setSearch((current) => ({
      ...current,
      status: ''
    }));
    getAllCompanies(1, pages.size, {});
    apiRef.current.hideFilterPanel();
  };

  return (
    <>
      <FormControl fullWidth className={"statusSelect"}>
        <InputLabel variant="standard" className={"selectLabel"}>
          Company status
        </InputLabel>
        <NativeSelect
          defaultValue={status ?? "status"}
          onChange={(e) => onSelectChange(e)}
        >
          <option name="status" hidden>
            Status
          </option>
          <option value={"active"}>Active</option>
          <option value={"suspended"}>Suspended</option>
          <option value={"inactive"}>Inactive</option>
        </NativeSelect>
      </FormControl>
      <Button onClick={() => clearFilters()}>Clear filter</Button>
    </>
  );
};
