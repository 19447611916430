// @vendors
import React, { useContext } from "react";
import { Link } from "react-router-dom";

// @state
import { useUI } from "../../../app/context/ui";

// @assets
import { DashboardStyles } from "../../../assets/css/dashboard-style";
import {
  componentsStyles,
  MenuProps,
} from "../../../assets/css/tableComponents-styles";
import CustomNoRowsOverlay from "../../../assets/CustomNoRowsOverlay";

// @material
import {
  Box,
  Button,
  ButtonGroup,
  DataGrid,
  EditIcon,
  ExpandMoreRoundedIcon,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "../../../components/shared/MaterialUI";

// @helpers
import AppHelper from "../../../helpers/AppHelper";
import { dataToUpdateCompany } from "../../../helpers/FunctionsUtils";

// @components
import { DataTable } from "../Admin";
import { CustomFiltering } from "./custom/CustomFiltering";
import { CustomPagination } from "./custom/CustomPagination";
import { CustomColumnsPanel } from "./custom/CustomPreferences";
import { CustomToolbar } from "./custom/CustomToolbar";

// @services
import CompanyServiceNewApi from "../../../services/newApi/CompanyServiceNewApi";

export const ClientTable = () => {
  const {
    rowsState,
    setRowsState,
    getAllCompanies,
    pages,
    search,
    sortModel,
    setPages
  } = useContext(DataTable);
  const styles = DashboardStyles();
  const { blockUI, snackbarUI } = useUI();
  const companyService = new CompanyServiceNewApi();

  const onStatusChange = async (e, row) => {
    try {
      blockUI.current.open(true);
      const response = await companyService.getCompanyById(row?.id);
      const data = response?.data?.data;
      const request = dataToUpdateCompany(data, e.target.value)
      updateCompany(request, row?.id, e)
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }    
  };

  const updateCompany = async (request, id, e) => {
    try {
      blockUI.current.open(true);
      await companyService.updateCompany(request, id);
      let newRows = rowsState.rows.map((r) => 
        (r.id === id ? { ...r, status: e.target.value } : r)
      );
      setRowsState({...rowsState, rows: newRows});
      getAllCompanies()
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  let statusOptions = ["active", "suspended", "inactive"];

  const columns = [
    {
      field: "name",
      headerName: "Company name",
      flex: 0.5,
      renderCell: (params) => (
        <Box
          component={Link}
          to={{
            pathname: `/employer/${params.row.id}`,
            state: { page: rowsState.page + 1 },
          }}
          sx={styles.employerLink}
        >
          {params?.row?.displayName ? params?.row?.displayName : params?.row?.name}
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Company Status",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.2,
      minWidth: 160,
      renderCell: (params) => (
        <Select
          value={params.row.status}
          IconComponent={ExpandMoreRoundedIcon}
          onChange={(e) => onStatusChange(e, params.row)}
          sx={styles.statusSelect}
          MenuProps={MenuProps}
        >
          {statusOptions.map((st, index) => (
            <MenuItem
              value={st}
              key={index}
              disabled={st === params.row.status}
              sx={{ textTransform: "capitalize" }}
            >
              {st}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "actionButtons",
      headerName: "",
      filterable: false,
      flex: 0.05,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <ButtonGroup
          variant="contained"
          size="medium"
          sx={styles.buttonTools}
          fullWidth={true}
        >
          <Tooltip
            title={<p style={{ fontSize: "16px", margin: "0" }}>Edit user</p>}
            arrow
          >
            <Link
              to={{
                pathname: `/edit-employer/${params.row.id}`,
                state: { page: rowsState.page + 1 },
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "transparent" }}
                children={<EditIcon style={{}} />}
              />
            </Link>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  const sortData = (sort) => {
    let orderByName = null
    let orderBy = null
    if(sort?.length){
      orderByName = true
      orderBy = sort[0]?.sort
    }
    setPages({
      page: pages.page,
      size: pages.size,
      hasOrder: orderByName,
      orderBy: orderBy
    })
    getAllCompanies(pages.page, pages.size, search, sort, orderByName, orderBy)
  }

  return (
    <Paper elevation={4} sx={styles.wrapperTable}>
      <DataGrid
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
          Pagination: CustomPagination,
          FilterPanel: CustomFiltering,
          ColumnsPanel: CustomColumnsPanel,
        }}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => sortData(newSortModel)}
        componentsProps={componentsStyles}
        headerHeight={80}
        rowHeight={70}
        paginationMode="server"
        pagination
        filterMode="server"
        disableSelectionOnClick
        checkboxSelection={false}
        sx={styles.tableGrid}
        {...rowsState}
      />
    </Paper>
  );
};
