import { combineReducers } from 'redux';
import user from './user';
import tabPayroll from './tabPayroll'
import filterPayroll from './filterPayrollReducer'
import pagination from './paginationReducer'
import editEmployeePayroll from './editPayrollReducer'
import getListPayroll from './callPayrollList'
import employeeSelected from './employeeSelectedReducer'
import sortEmployeeReducer from './sortEmployeeReducer';

const reducer = combineReducers({
  user,
  tabPayroll,
  filterPayroll,
  pagination,
  editEmployeePayroll,
  getListPayroll,
  employeeSelected,
  sortEmployeeReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }  
  return reducer(state, action);
};

export default rootReducer;